const capitalize = (word: string) => {
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
};

const isEqual = (first?: string | null, second?: string | null) => {
  if (!first || !second) return false;

  return first.toLowerCase() === second.toLowerCase();
};

const parse = (jsonString: string) => {
  return JSON.parse(jsonString, (_, value) => {
    if (typeof value === "string" && /^\d+n$/.test(value)) {
      return BigInt(value.slice(0, -1));
    } else {
      return value;
    }
  });
};

export { capitalize, isEqual, parse };
